import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"

export default function Mission({ data }) {
  const statements = data.allStellingenCsv.edges
  const renderStatements = statements.map(statement => (
    <li key={statement.node.Id_stelling}>{statement.node.Stelling}</li>
  ))
  return (
    <Layout>
      <div className="full-text-button">
        <div className="full-text-button_text full-text-button_text--top">
          <div>
            <h3>
              Dankzij de bibliotheekdiensten doet iedereen mee.
              <br></br>
              <br></br>
              <span className="has-text-weight-light">
                De bibliotheek helpt je ontdekken. Van je eerste woordjes, naar
                jouw moment van rust tot de volgende stap in je carrière. Altijd
                en overal!
              </span>
            </h3>
            <p>
              <strong>
                Hieronder vind je de {statements.length} doelstellingen
              </strong>{" "}
              die laten zien waar we nu staan en waar we naartoe willen als
              bibliotheek. De doelstellingen dragen bij aan het verwezenlijken
              van de missie.
            </p>
            <ul className="list has-margin-bottom-medium">
              {renderStatements}
            </ul>
          </div>
        </div>
        <div className="full-text-button_button">
          <Link className="button" to="/verhalen/">
            Ga naar de verhalen
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MissionPageQuery {
    allStellingenCsv {
      edges {
        node {
          Uitleg_van_de_stelling
          Stelling
          Id_stelling
        }
      }
    }
  }
`
